import { useAppSelector } from "@src/ducks/ducksHook";
import { selectSubscriptionActive, selectSubscriptionMaxUsers } from "@src/ducks/slices/user.slice";
import DrawerListData from "@src/utils/mockdata/drawer-list";
import React, { useContext, useState } from "react";
import { View } from "react-native";
import { DEVICE_SIZES, useDeviceSize } from "rn-responsive-styles";
import { DrawerContext } from "../../drawer-provider";
import { DrawerComponentContext } from "../provider";
import DrawerItem from "./drawer-item";
import useStyles from "./styles.css";
import { selectUserEmail, } from "@src/ducks/slices/user.slice";


const DrawerList = () => {
  const styles = useStyles();
  const device_size = useDeviceSize();
  const maxUser = useAppSelector(selectSubscriptionMaxUsers);
  const { activeTab, onActiveTab } = useContext(DrawerContext);
  const { onMinimizeState } = useContext(DrawerComponentContext);
  const isSubscribeActive = useAppSelector(selectSubscriptionActive);
  const [hover, setHover] = useState(
    new Array<boolean>(DrawerListData.length).fill(false)
  );
  const userEmail = useAppSelector(selectUserEmail);
  const DrawerAccessList = React.useMemo(() => {
    console.log("Drawer List Data", DrawerListData)
    
    return DrawerListData.reduce((result: any, value: any) => {
      
      if(!isSubscribeActive && value.name === "Manage Users"){
        // do nothing
      }else if(maxUser <= 1 && value.name === "Manage Users"){
        // do nothing
      }else if (value.name === "SafeForm Lite" && !userEmail?.endsWith("@unawa.asia")){
          // do nothing
      }else{
        result.push(value);
      }

      return result;
    },[]);
  },
  [isSubscribeActive, maxUser, userEmail]);

  const handleHover = React.useCallback((eventName: string, idx: number) => {
    const isHover = eventName === "hoverIn";
    const item = DrawerAccessList[idx];
    if (item.name !== 'NotarizeIT') {
      setHover((prevState) => ({
        ...prevState,
        [idx]: isHover,
    }));
    }
  },[]);

  const onToggle = (itemName : string) => {
    if (itemName === 'NotarizeIT'){ 
      return
    }
    if (DEVICE_SIZES.XS === device_size || DEVICE_SIZES.SM === device_size || DEVICE_SIZES.MD === device_size) {
      onMinimizeState();
    }
    onActiveTab(itemName);
  };
  console.log("Drawer List Data", DrawerAccessList)
  return (
    <View style={styles.v_margintop}>
      {DrawerAccessList.map((item: any, index: number) => {
        const isHover = hover[index];
        const isActive = item.name === activeTab;

        return (
          <DrawerItem
            key={`tabs${index}`}
            isActive={isActive}
            isHover={isHover}
            item={item}
            onPress={() => onToggle(item.name)}
            onPressIn={() => handleHover("hoverIn", index)}
            onPressOut={() => handleHover("hoverOut", index)}
            onHoverIn={() => handleHover("hoverIn", index)}
            onHoverOut={() => handleHover("hoverOut", index)}
          />
        );
      })}
    </View>
  );
};

export default DrawerList;
