import Icon from "@expo/vector-icons/MaterialIcons";
import Button from "@src/components/button/Button04";
import { useAppSelector } from "@src/ducks/ducksHook";
import { useDashboardService } from "@src/ducks/hooks";
import { selectSubscriptionActive,selectSubscriptionExpiration, selectSubscriptionFullSlot, selectSubscriptionMaxUsers, selectSubscriptionRole } from "@src/ducks/slices/user.slice";
import React from "react";
import { View, Text } from "react-native";
import { DrawerContext } from "../../drawer-provider";
import useStyles from "./styles.css";
import BannerModal from '../../../dashboard/banner-new/banner-modal'

const getButtonLabel = (title: string) => {
  switch(title){
  case "Signatures":
    return "Upload Signature";
  case "Manage Users":
    return "Invite User";
  case "Inbox":
    return "Create Message";
  case "Dashboard":
  case "Documents":
  default:
    return "Create Document";
  }
};

const CreateButton = () => {
  const styles = useStyles();
  const { onDrawerEnable } = useDashboardService();
  const maxUser = useAppSelector(selectSubscriptionMaxUsers);
  const fullSlot = useAppSelector(selectSubscriptionFullSlot);
  const subscriptionRole = useAppSelector(selectSubscriptionRole);
  const isSubscribeActive = useAppSelector(selectSubscriptionActive);
  const isNotExpired = useAppSelector(selectSubscriptionExpiration);
  const { activeTab, onActiveTab, onSetActiveSecondScreen, onAddTeamButton } = React.useContext(DrawerContext);
  const ButtonLabel = React.useMemo(() => getButtonLabel(activeTab), [activeTab]);
  const [showModal, setShowModal] = React.useState(false)
  let shouldShowCreateButton = true

  const onPress = React.useCallback(() => {
    switch(activeTab){
    case "Signatures":
      onSetActiveSecondScreen("signature-upload");
      break;
    case "Manage Users":
      onAddTeamButton(true);
      break;
    case "Dashboard":
    case "Documents":
    case "Support":
    case "Document Sent":
      onDrawerEnable(false);
      onActiveTab("Create Document");
      break;
    }
  },[activeTab]);

  const onUpgrade = React.useCallback(() => {
    setShowModal(true)
  }, [])

  if(isNotExpired && !isSubscribeActive){
  }
  else if(!isSubscribeActive && ["Dashboard", "Documents", "Manage Users"].includes(activeTab)){
    shouldShowCreateButton = false
  }else if(!["Owner", "Admin"].includes(subscriptionRole) && ["Manage Users"].includes(activeTab)){
    shouldShowCreateButton = false
  }else if(maxUser <=1 && ["Manage Users"].includes(activeTab)){
    shouldShowCreateButton = false
  }else if(fullSlot && ["Manage Users"].includes(activeTab)){
    shouldShowCreateButton = false
  }else if (activeTab === "Inbox"){
    shouldShowCreateButton = false
  }else if (activeTab === "SafeForm Lite"){
    shouldShowCreateButton = false
  }else if (["Get Free Trial"].includes(activeTab)){
    shouldShowCreateButton = false
  }else if (["Support"].includes(activeTab)){
    shouldShowCreateButton = false
  }
  

  return shouldShowCreateButton ? (
    <Button
      onPress={onPress}
      style={styles.btn_createdocument} >
      <>
        <Text style={styles.btn_label}>{ButtonLabel}</Text>
        <Icon name="add" size={15} color="white" />
      </>
    </Button>
  ) : ["Dashboard"].includes(activeTab) ? (
    <>
      <Button
        onPress={onUpgrade}
        style={styles.disabled_createdocument} >
        <>
          <Text style={styles.btn_label}>{ButtonLabel}</Text>
          <Icon name="add" size={15} color="white" />
        </>
      </Button>
      <BannerModal visible={showModal} onBack={() => setShowModal(false)}/>
    </>
  ) : <></>;
};

export default CreateButton;
