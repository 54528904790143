import React from "react";
import { selectedSignSecureLoading } from "@src/ducks/slices/signsecure.slice";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import { getNextSteps } from "@src/utils/filter-helper";
import { useSignsecureService } from "@src/ducks/hooks";
import { useAppSelector } from "@src/ducks/ducksHook";
import { Button04 } from "@src/components/button";
import useStyles from "./styles.css";

const SendButton = () => {
  const styles = useStyles();
  const { createDocumentRequest } = useSignsecureService();
  const isLoading = useAppSelector(selectedSignSecureLoading);
  const { sendActive, activeDocument, onSetCreateDocumentScreen } = React.useContext(DrawerContext);
  const buttonLabel = React.useMemo(() => {
    if(isLoading){
      return "Sending";
    } else if(activeDocument !== "REVIEW"){
      return "SEND";
    } else {
      return "SEND";
    }

  },[isLoading, activeDocument]);

  const onSend = () => {
    if(activeDocument !== "REVIEW"){
      onSetCreateDocumentScreen(getNextSteps(activeDocument));
    }else{
      createDocumentRequest();
    }
  };
  
  return (
    <Button04
      onPress={onSend}
      isLoading={isLoading}
      style={[styles.btn_send]}
      labelStyle={styles.label_send}
      label={buttonLabel}
      disabled={sendActive === false}
    />
  );
};

export default React.memo(SendButton);
