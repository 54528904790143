import images from "@assets/images";
import { Button01 } from "@src/components/button";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import React, { useContext } from "react";
import { Modal, Text, View, ImageBackground, } from "react-native";
import { mobileSize } from "@src/utils/screensize-helper";
import useStyles from "./styles.css";
import colors from "@assets/colors";
import { LinearGradient } from "expo-linear-gradient";

type Props = {
  visible: boolean;
  onBack: () => void;
}

const BannerContent= ({ visible, onBack } : Props) => {
  const styles = useStyles();
  const isMobile = mobileSize();
  const { onActiveTab } = useContext(DrawerContext);

  return (
      <Modal
        transparent
        visible={visible}
        animationType={"none"}
        onRequestClose={onBack}
        supportedOrientations={[
          "portrait",
          "portrait-upside-down",
          "landscape",
          "landscape-left",
          "landscape-right",
        ]}
      >
        
        <View style={{ flex:1,  alignItems: "center", justifyContent: "center", backgroundColor: colors.blurBG3, height: "100%",}}>
            
            <View style={{ width: 500*.7, height: 500, backgroundColor: '#1D242C' }}>
                <LinearGradient
                  colors={["#1D242C", "#1D242C", "#1D242C", "#1D242C"]}
                  style={[styles.linearStyle]}
                />
                <View style={{ top: -25, width: '100%', height: "50%" }}>
                  <ImageBackground
                      resizeMode="contain"
                      source={images.bg_banner_two}
                      style={{ 
                        width: '65%',
                        height: '80%',
                        position: 'absolute',
                        right: "-20%",
                        // top: 0,
                        bottom: "-13.3%",
                        zIndex: 1,
                      }} 
                    />
                    <ImageBackground
                      resizeMode="contain"
                      source={images.bg_banner_mobile}
                      style={{ width: "100%",
                      height: '100%',
                      zIndex: -1, 
                      position: "absolute", }} 
                    />
              </View>
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Text style={styles.textContent1}>
                  <Text style={styles.boldText}>
                    Upgrade now to use this feature!
                  </Text>
                </Text>
                <Text style={[styles.textContent, styles.regularText]}>
                  Choose the perfect plan for you needs.
                </Text>
              </View>
              <Button01
                label={"EXPLORE PLANS"}
                onPress={() => onActiveTab("Upgrade Plan")}
                style={[styles.btn_explore_plans]}
                labelStyle={styles.btn_explore_plans_label}
              />
              <Button01
                label={"Go Back"}
                onPress={onBack}
                style={[styles.btn_explore_plans_back]}
                labelStyle={styles.boldText}
              />
            </View>
          
        </View>
      </Modal>
  );
};

export default BannerContent;