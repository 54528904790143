import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import React, { useEffect, useState } from "react";
import { Linking, Text, View } from "react-native";
import useStyles from "./styles.css";

const CopyRightContent = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [ currentYear , setCurrentYear ] = useState("");

  useEffect (() => {
    const year = new Date().getFullYear();
    setCurrentYear(
      year + ""
    );
  }, []);
  

  return (
    <View style={[styles.drawer_content_footer]}>
      <Text style={styles.copyRightStyle}>
        <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("terms-condition")}>Terms & Conditions</Text> |
        <Text style={styles.copyRightBtnStyle} onPress={() => navigation.navigate("privacy-policy")}> Privacy Policy</Text>
        {"\n"}
        © { currentYear + " " } 
        <Text style={styles.copyRightBtnStyle} onPress={() => Linking.openURL("https://www.unawa.asia")}>
          UNAWA
        </Text>. All rights reserved
        { "\n"}
        Ver. 2.0.10
      </Text>
    </View>
  );
};

export default CopyRightContent;
