import { Button01 } from "@src/components/button";
import { selectAuthLoginInput } from "@src/ducks/slices/auth.slice";
import { useFetchPost } from "@src/utils/api/hooks";
import useOrientationHelper from "@src/utils/orientation-helper";
import React from "react";
import { TouchableOpacity, Text, View } from "react-native";
import { useSelector } from "react-redux";
import useStyles from "./styles.css";

const EmailSent = () => {
  const styles = useStyles();
  const isLandScape = useOrientationHelper();
  const { email } = useSelector(selectAuthLoginInput);
  const { loading, data, runRequest } = useFetchPost();
  const [didResend, setDidResend] = React.useState(false);

  const onResend = () => {
    setDidResend(true);
    runRequest("/auth/resend-verification", { email });
  };

  return (
    <View style={isLandScape ? styles.v_signup_landscape : styles.v_signup }>
      <Text style={styles.t_email_sent_notes2}>
        Did not receive the email?
        {!didResend && 
          <TouchableOpacity onPress={onResend}>
            <Text style={{ fontWeight: 'bold' }}> Resend</Text>
          </TouchableOpacity>
        }
        {didResend &&
          ' Check your spam or junk folder'
        }
      </Text>
    </View>
  );
};

export default EmailSent;
