import colors from "@assets/colors";
import Icon from "@expo/vector-icons/MaterialIcons";
import { CommonActions, useNavigation } from "@react-navigation/native";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectAuthLoggedIn } from "@src/ducks/slices/auth.slice";
import { DrawerContext } from "@src/screens/drawer-screens/drawer-provider";
import React, { useContext } from "react";
import { Text, TouchableOpacity } from "react-native";
import useStyles from "./styles";


const BackButton = ({ color }: any) => {
  const styles = useStyles();
  const navigation = useNavigation();
  const { onActiveTab } = useContext(DrawerContext);
  const isLoggedIn = useAppSelector(selectAuthLoggedIn);
  
  const onPress = () => {
    if(!isLoggedIn){
      navigation.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            { name: "Signin" },
          ],
        })
      );
    }else{
      onActiveTab("Upgrade Plan");
    }
  };

  return (
    <TouchableOpacity activeOpacity={0.8} style={styles.v_invite_link_wrapper} onPress={onPress}>
      <Icon name="keyboard-arrow-left" size={22} color={color || colors.black3} />
      <Text numberOfLines={1} style={[styles.header_title, { color: color || colors.black3 }]}> Back</Text>
    </TouchableOpacity>
  );
};

export default BackButton;
