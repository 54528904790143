import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES, maxSize } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    v_body: { flex: 1, flexDirection: "row" },
    container: {
      width: "50%",
      height: "100%",
      alignItems: "center",
      backgroundColor: colors.white,
    },
    cardsSpacing:  {
      flex: 1,
      justifyContent: "center",
      alignItems: "center", width:"100%" 
    },

    t_title: {fontFamily: "DMSans-Bold", fontSize: 24, marginTop: 43,
      lineHeight: 14, color: colors.black3, textAlign: "center"
    },
    t_notes: {
      fontFamily: "DMSans",
      fontWeight: "400",
      fontSize: 16,
      lineHeight: 18,
      marginTop: 39,
      maxWidth: 500,
      textAlign: "center",
      color: colors.black3,
    },
    buttonStyling: {
      flexDirection: "column", 
      alignItems: "center"
    },
    labelStyle: { fontFamily: "DMSans-Bold" },
    btn_proceed: { width: 250, height: 47, marginTop: 80, backgroundColor: colors.blue },

    // COPY-RIGHT
    copyRight: { display: "none" },
    copyRightStyle: StyleSheet.flatten([
      TypographyStyle.copyRightText,
      { bottom: 100,
        alignSelf: "center",textAlign: "center", 
        color: colors.black },
    ]),
    copyRightBtnStyle: StyleSheet.flatten([TypographyStyle.copyRightButtonMobile]),


    signInCenter: { marginTop: 30 },
    v_signup: {
      height: "100%",
      alignSelf: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    v_signup_landscape: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.white,
    },
    t_email_sent: StyleSheet.flatten([
      TypographyStyle.heading6Bold,
      { color: colors.black3, }
    ]),
    t_email_sent_notes: StyleSheet.flatten([
      { marginTop: 45, color: "#4B4B4B",  },
    ]),
    t_email_sent_notes2: StyleSheet.flatten([
      TypographyStyle.fieldsTitle,
      { width: 250, marginTop: 34, textAlign: "center", color: "#4B4B4B" },
    ]),
    btn_backtologin: {
      height: 60,
      marginTop: 45,
      marginHorizontal: 30,
      backgroundColor: colors.blue,
    },
  },
  {
    [DEVICE_SIZES.XL]: {
      v_signup: { paddingHorizontal: "12%" },
    },
    [DEVICE_SIZES.LG]: {
      t_title: { fontSize: 18, lineHeight: 10.5, marginTop: 35, },
      t_notes: { fontSize: 14, lineHeight: 13.5, marginTop: 28, maxWidth: 430 },
      btn_proceed: { width: 187, height: 44, marginTop: 40, borderRadius: 6 },
      labelStyle: { fontSize: 10.5, lineHeight: 10.5 },

      v_signup: { paddingHorizontal: "8%" },
      t_email_sent: { fontSize: 30, lineHeight: 39 },
      t_email_sent_notes: { fontSize: 18, lineHeight: 21, marginTop: 38 },
      t_email_sent_notes2: { fontSize: 10.5, lineHeight: 10.5, marginTop: 24 },
      btn_backtologin: { height: 45, borderRadius: 6 },
    },
    [maxSize(DEVICE_SIZES.MD)]: {
      v_body: { flex: 1, flexDirection: "column" },
      container: {
        minWidth: "100%",
        maxWidth: "100%",
      },
      buttonStyling: {
        alignItems: "center"
      },
      btn_proceed: { width: 350, height: 47, marginTop: 46, backgroundColor: colors.blue },
      copyRight:{
        display:"flex",
        bottom: 0
      },

      v_signup: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingTop: 100,
        alignSelf: "center",
        paddingVertical: 0,
        paddingHorizontal: "20%",
        justifyContent: "flex-start",
      },
    },
    [maxSize(DEVICE_SIZES.SM)]: {
      v_body: {
        flexDirection: "column",
        width: "100%",
        height: "100%",
      },
      v_signup: {
        minWidth: "100%",
        maxWidth: "100%",
        paddingTop: 90,
        paddingVertical: 0,
        paddingHorizontal: 20,
        justifyContent: "flex-start",
      },
      t_email_sent: {
        fontSize: 22,
        lineHeight: 25,
      },
      t_email_sent_notes: {
        fontSize: 16,
        lineHeight: 20,
      },
      t_email_sent_notes2: {
        fontSize: 12,
        lineHeight: 20,
      },
    },
    
  }
);

export default useStyles;
