// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@src/ducks/store";
import { ErrorValue, Onboading } from "../types";

interface StateValue {
  onboarding: Onboading[]
  loading: boolean;
  drawerEnable: boolean;
  activeDrawer: string;
  error: ErrorValue;
  document: any;
  isMaintenance: boolean;
  cancelReasons: string[];
}

export const initialState: StateValue = {
  onboarding: [] as Onboading[],
  loading: false,
  activeDrawer: "Dashboard"
} as StateValue;

// Slice
export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setTakeTour: (state, action) => {
      state.onboarding = state?.onboarding ? 
      state.onboarding?.find(item => item.email === action.payload) ?
      state.onboarding.map((onboarding) => {
          if (onboarding.email === action.payload) {
            return {
              ...onboarding,
              didTakeTour: true
            }
          }
          return onboarding
      }) : [...state.onboarding, {  
        email: action.payload,
        didTakeTour: true,
        finishedOnboaring: false,
        didMigrateFiles: false,
      }] : [{  
        email: action.payload,
        didTakeTour: true,
        finishedOnboaring: false,
        didMigrateFiles: false,
      }]
    },
    setMigratedFiles: (state, action) => {
      state.onboarding = state?.onboarding ? 
      state.onboarding?.find(item => item.email === action.payload) ?
      state.onboarding.map((onboarding) => {
          if (onboarding.email === action.payload) {
            return {
              ...onboarding,
              didMigrateFiles: true
            }
          }
          return onboarding
      }) : [...state.onboarding, {  
        email: action.payload,
        didMigrateFiles: true,
        finishedOnboaring: false,
        didTakeTour: false
      }] : [{  
        email: action.payload,
        didMigrateFiles: true,
        finishedOnboaring: false,
        didTakeTour: false,
      }]
    },
    setFinishedOnboarding: (state, action) => {
      state.onboarding = state?.onboarding ? 
      state.onboarding?.find(item => item.email === action.payload) ?
      state.onboarding.map((onboarding) => {
          if (onboarding.email === action.payload) {
            return {
              ...onboarding,
              finishedOnboaring: true
            }
          }
          return onboarding
      }) : [...state.onboarding, {  
        email: action.payload,
        finishedOnboaring: true,
        didMigrateFiles: false,
        didTakeTour: false
      }] : [{  
        email: action.payload,
        finishedOnboaring: true,
        didMigrateFiles: false,
        didTakeTour: false,
      }]
    },
    drawerEnable: (state, action) => {
      state.drawerEnable = action.payload;
    },
    activeDrawer: (state, action) => {
      state.activeDrawer = action.payload;
    },
    setDocument: (state, action) => {
      state.document = action.payload;
    },
    setConfig: (state, action) => {
      state.isMaintenance = action.payload.isMaintenance;
      state.cancelReasons = action.payload.cancelReasons;
    },
    resetDashboard: (state) => {
      return { ...initialState, 
        onboarding: state.onboarding,
      };
    },
  },
});

// Actions
export const dashboardActions = {
  setTakeTour: dashboardSlice.actions.setTakeTour,
  setMigratedFiles: dashboardSlice.actions.setMigratedFiles,
  setFinishedOnboarding: dashboardSlice.actions.setFinishedOnboarding,
  drawerEnable: dashboardSlice.actions.drawerEnable,
  activeDrawer: dashboardSlice.actions.activeDrawer,
  setDocument: dashboardSlice.actions.setDocument,
  setConfig: dashboardSlice.actions.setConfig,
  resetDashboard: dashboardSlice.actions.resetDashboard,
};

// Selectors
export const selectedDashboardLoading = (state: RootState) => state.dashboard.loading;
export const selectedOnboarding = (state: RootState) => state.dashboard.onboarding;
export const selectedDrawerStatus = (state: RootState) => state.dashboard.drawerEnable;
export const selectedActiveDrawer = (state: RootState) => state.dashboard.activeDrawer;
export const selectedDocument = (state: RootState) => state.dashboard.document;
export const selectedMaintenanceStatus = (state: RootState) => state.dashboard.isMaintenance;
export const selectedCancelReason = (state: RootState) => state.dashboard.cancelReasons;

// Reducer
export default dashboardSlice.reducer;
