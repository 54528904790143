

import { SagaIterator } from "@redux-saga/core";
import * as API from "@src/utils/api";
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
/* eslint-disable-line @typescript-eslint/no-unused-vars */
import { gtag } from "ga-gtag";

// Types
import * as Types from "../types";
import { WEB_BASE_URL } from "@env";

// API
import {
  createMayaCustomer,
  forgotpassword,
  generateMayaToken,
  linkGeneratedMayaTokenToCustomer,
  login,
  loginFaceID,
  loginMicrosoft,
  paymayaTransaction,
  refreshToken,
  register,
  resetpassword,
  verifyPromoCode,
} from "@src/utils/api";

// Slice
import { authActions, selectAuthLoginInput, selectedAuthAccessToken, selectAuthGeneratedOTP, selectAuthValidatedOTP } from "../slices/auth.slice";
import { dashboardActions } from "../slices/dashboard.slice";
import { forgotActions } from "../slices/forgot.slice";
import { signsecureActions } from "../slices/signsecure.slice";
import { signupActions } from "../slices/signup.slice";
import { userActions, selectUserDetails } from "../slices/user.slice";
import moment from "@src/utils/moment-timezone-helper";

// function* handleSignin(action: {
//   type: typeof authActions.loginRequest;
//   payload: Types.LoginInput;
// }): SagaIterator {
//   try {
//     const user = yield call(login, action.payload);

//     yield put(authActions.loginSuccess({...user.data, loginType: "Login via Email"}));
//     gtag("event", "login", { "method": "Login via Email" });

//     const validatedOTP: Types.ValidatedOTP = yield select(selectAuthValidatedOTP);
    
//     const session = validatedOTP?.sessions?.find(session => session.email === action.payload.email);
//     if (session) {
//       const targetTime = new Date(session.sessionId ?? "").getTime();
//       const currentTime = new Date().getTime();
//       const remainingTime = Math.max(targetTime - currentTime, 0);
//       const requiredOTP = remainingTime <= 0;

//       console.log(`"Re attempt: ${session.sessionId} | ${!requiredOTP} ${remainingTime}`);
//       if (session.sessionId && !requiredOTP) {
//         yield put(authActions.validateOTPSuccess({ 
//           sessionId: session?.sessionId,
//           email: session?.email,
//           valid: true 
//         } as any));
//       }
//       else {
//         yield put(authActions.generateOTP());
//       }
//     } else {
//       yield put(authActions.generateOTPSuccess({} as any));
//       yield put(authActions.generateOTP());
//     }
//   } catch (error: any) {    
//     const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
//     const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

//     yield put(authActions.loginFailure({ message: failedMessage, status: error?.status ?? 500}));
//     yield delay(1000);
//     yield put(authActions.loginFailure({}));
//   }
// }

function* handleSignin(action: {
  type: typeof authActions.loginRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(login, action.payload);

    console.log(`handleSignin: `, user.data)
    yield put(authActions.loginSuccess({...user.data, loginType: "Login via Email"}));
    gtag("event", "login", { "method": "Login via Email" });

    if (!!user.data?.accessToken) {
      
      yield put(authActions.validateOTPSuccess({ 
        email: action.payload.email,
        isLoginOtpEnabled: false,
        valid: true 
      } as any));
    } else {
      yield put(authActions.generateOTPSuccess({
        ...action.payload,
      } as any));
      yield put(authActions.generateOTP());
    }
  } catch (error: any) {    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage, status: error?.status ?? 500}));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleFaceIDSignin(): SagaIterator {
  try {
    const { email } = yield select(selectAuthLoginInput); 
    const user = yield call(loginFaceID, {email});
    const param ={ 
      id: user.data?.id,
      role: user.data?.role,
      userId: user.data?.id,
      email: user.data?.email,
      givenName: user.data?.givenName,
      lastName: user.data?.lastName,
      accessToken: user.data?.accessToken,
      refreshToken: user.data?.refreshToken,
      loginType: "Login via Face Recognition"
    };
    yield put(authActions.validateOTPSuccess({ valid: true } as any));
    yield put(authActions.loginSuccess(param));
    gtag("event", "login", { "method": "Login via Face Recognition" });
  } catch (error: any) {
    console.log("error", error, typeof error.error);
    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage }));
    yield delay(1000);
    // yield put(authActions.loginFailure({}));
  }
}

function* handleMicrosoftSignin(params : any): SagaIterator {
  try {
    console.log("handleMicrosoftSignin",params);
    let name = "",givenName =  "",  lastName = "";
    if(params.payload?.name){
      name = params.payload?.name.split(" ", 2);
      givenName = name[0];
      lastName = name[1];
    }
    

    
    const user = yield call(loginMicrosoft, {email : params.payload?.username, givenName:givenName, lastName:lastName});
    const param ={ 
      id: user.data?.id,
      role: user.data?.role,
      userId: user.data?.id,
      email: user.data?.email,
      givenName: user.data?.givenName,
      lastName: user.data?.lastName,
      accessToken: user.data?.accessToken,
      refreshToken: user.data?.refreshToken,
      loginType: "Login via Face Recognition"
    };
    yield put(authActions.validateOTPSuccess({ valid: true } as any));
    yield put(authActions.loginSuccess(param));
    gtag("event", "login", { "method": "Login via Microsoft" });
  } catch (error: any) {
    console.log("error", error, typeof error.error);
    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage }));
    yield delay(1000);
    // yield put(authActions.loginFailure({}));
  }
}

function* handleGenerateOTP(): SagaIterator {
  try {
    // yield delay(1000);
    const validatedOTP = yield select(selectAuthValidatedOTP);
    // const userDetails = yield select(selectUserDetails);
    const generatedOTP = yield select(selectAuthGeneratedOTP)
    const generatedOTPByEmail: Types.ValidatedOTPSession = validatedOTP?.sessions?.find((session: any) => session.email === generatedOTP.email);
    const targetTime = generatedOTPByEmail?.createdAt ?? 0;
    const currentTime = new Date().getTime();
    const remainingTime = Math.max(targetTime - currentTime, 0);
    const generatedOTPExpired =  Boolean(remainingTime <= 0);

    if (generatedOTPExpired) {
      // const accessToken = yield select(selectedAuthAccessToken);
      // const result = yield call(API.generateOTP, { accessToken });
      // console.log("generateOTP", result);

      const updatedGeneratedOTP = { 
        ...generatedOTP,
        otpExpiration: +moment().add(3, "minutes"), 
        updatedAt: +moment().add(3, "minutes") 
      } as any;
      yield put(authActions.generateOTPSuccess(updatedGeneratedOTP));
      yield delay(1000);
      yield put(authActions.generateOTPSuccess({ ...updatedGeneratedOTP, createdAt: +moment().add(3, "minutes"), updatedAt: null }));
      yield put(authActions.validateOTPSuccess({ createdAt: +moment().add(3, "minutes"), email: generatedOTP.email, isLoginOtpEnabled: true } as any));
    }
    else {
      const updatedGeneratedOTP = {
        ...generatedOTP,
        otpExpiration: generatedOTPByEmail.sessionId,
        createdAt: targetTime+1,
        updatedAt: +moment().add(3, "minutes"),
      } as any;
      yield put(authActions.generateOTPSuccess(updatedGeneratedOTP));
      yield delay(1000);
      yield put(authActions.generateOTPSuccess({ ...updatedGeneratedOTP, updatedAt: null }));
    }
  } catch (error: any) {    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage, status: error?.status ?? 500}));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleValidateOTP(action: {
  type: typeof authActions.loginRequest;
  payload: Types.GeneratedOTP;
}): SagaIterator {
  try {
    const otpCode = action.payload.code;
    // const accessToken = yield select(selectedAuthAccessToken);
    // const generated = yield select(selectAuthGeneratedOTP);
    // const result = yield call(API.validateOTP, {
    //   otp: otpCode,
    //   sessionId: generated.sessionId,
    //   accessToken,
    // });
    // if(result.data.valid){

    const generated = yield select(selectAuthGeneratedOTP);
    const user = yield call(API.loginValidateOTP, {
      email: generated?.email,
      otp: otpCode,
    });
    console.log("userVERIFIED: ", user)
    if(!!user.data?.accessToken){
      yield put(authActions.loginSuccess({...user.data, loginType: "Login via Email"}));
      yield put(authActions.validateOTPSuccess({ 
        valid: true, 
        sessionId: +moment().add(7, "days"), 
        email: generated?.email,
        isLoginOtpEnabled: true
      }as any));
    }else{
      const message = "Invalid OTP Code";
      const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

      yield put(authActions.validateOTPFailure({ message: failedMessage, status: 201}));
      yield delay(1000);
      yield put(authActions.validateOTPFailure({}));
    }

  } catch (error: any) {    
    const message = typeof error.error === "object" ? error.error[0] : error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(authActions.loginFailure({ message: failedMessage, status: error?.status ?? 500}));
    yield delay(1000);
    yield put(authActions.loginFailure({}));
  }
}

function* handleSignup(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupInput;
}): SagaIterator {
  try {
    const user = yield call(register, action.payload);

    yield put(signupActions.signupSuccess(user.data));
    yield delay(1000);
    yield put(signupActions.signupSuccess({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signupActions.signupFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(signupActions.signupFailure({}));
  }
}

function* handleSignupWithPromo(action: {
  type: typeof signupActions.signupRequest;
  payload: Types.SignupWithPromoValue;
}): SagaIterator {
  try {
    let cost = action.payload.cost;

    if(action.payload.promoCode){
      const promoDiscount = yield call(verifyPromoCode, {
        code: action.payload.promoCode,
        email: action.payload.email,
        subscriptionType: action.payload.subscriptionType,
      });

      cost = promoDiscount.data.finalPrice;
    }

    const customerParams = {
      "contact": {
        "phone": "",
        "email": action.payload.email
      },
      "firstName": action.payload.givenName,
      "middleName": "",
      "lastName": action.payload.lastName,
      "customerSince": moment().format("YYYY-MM-DD"),
    };
    const customer = yield call(createMayaCustomer, customerParams);
    const token = yield call(generateMayaToken, {card: action.payload.card });
    const cardToken = yield call(linkGeneratedMayaTokenToCustomer, {
      customerId: customer.id,
      isDefault: true,
      paymentTokenId: token.paymentTokenId
    });
    const transaction = yield call(paymayaTransaction, customer.id, cardToken.cardTokenId, {
      totalAmount: {
        amount: cost,
        currency: "PHP"
      },
      redirectUrl: {
        success: `${WEB_BASE_URL}/payment-success`,
        failure: `${WEB_BASE_URL}/payment-failed`,
      },
      requestReferenceNumber: action.payload.id,
      metadata: {
        pf: {
          smi: "",
          smn: `${action.payload.givenName} ${action.payload.lastName}`,
          mpc: "PHP",
          mco: "PHL",
        },
        user: {
          email: action.payload.email,
          firstName: action.payload.givenName,
          lastName: action.payload.lastName,
          password: action.payload.password,
          promoCode: action.payload.promoCode,
          mayaCustomerId: customer.id,
          mayaCardtokenId: cardToken.cardTokenId,
        },
        subMerchantRequestReferenceNumber: "",
      },
    });

    window.location.href = transaction.verificationUrl;
    yield put(signupActions.signupSuccess({}));
  } catch (error: any) {
    const message = error.message || error.error || "Something went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(signupActions.signupFailure({ message: failedMessage }));
    yield delay(1000);
    yield put(signupActions.signupFailure({}));
  }
}

function* handleForgotpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.LoginInput;
}): SagaIterator {
  try {
    const user = yield call(forgotpassword, action.payload);

    yield put(forgotActions.forgotSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(forgotActions.forgotFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.forgotFailed({}));
  }
}

function* handleResetpassword(action: {
  type: typeof forgotActions.forgotRequest;
  payload: Types.ResetPasswordInput;
}): SagaIterator {
  try {
    const user = yield call(resetpassword, action.payload);

    yield put(forgotActions.resetSuccess(user.data));
  } catch (error: any) {
    const message = error.message || error.error || "Somthing went wrong";
    const failedMessage = message?.replace(/^(HttpException:|NotFoundException:)\s*/, "");

    yield put(forgotActions.resetFailed({ message: failedMessage }));
    yield delay(1000);
    yield put(forgotActions.resetFailed({}));
  }
}

function* refreshTokenWorker(action: {
  type: typeof authActions.refreshTokenRequest;
  payload: Types.SessionValue;
}): SagaIterator {
  try {
    // Delay for 1 hour (in milliseconds) before triggering the token refresh again
    yield delay(60 * 30 * 1000);
    
    // Make an API call to refresh the token
    const result = yield call(refreshToken, action.payload);

    // Dispatch an action to update the token in the Redux store
    yield put(authActions.refreshTokenUpdate(result.data.accessToken)); // Replace 'UPDATE_TOKEN' with your actual action type

    // Restart the token refresh process by calling the same worker saga recursively
    yield call(refreshTokenWorker, action);
  } catch (error) { /* empty */ }
}

function* handleLoggout(): SagaIterator {
  yield put(dashboardActions.resetDashboard()); // Reset dashboard states
  yield put(signsecureActions.resetSignSecure()); // Reset signsecure states
  yield put(signsecureActions.setRoutes("")); // Reset signsecure states
  yield put(userActions.resetUser()); // Reset users states
}

// Watcher Saga
function* authWatcherSaga(): SagaIterator {
  yield takeLatest(authActions.generateOTP.type, handleGenerateOTP);
  yield takeLatest(authActions.validateOTP.type, handleValidateOTP);
  yield takeEvery(authActions.loginRequest.type, handleSignin);
  yield takeEvery(authActions.faceIDloginRequest.type, handleFaceIDSignin);
  yield takeEvery(authActions.microsoftLoginRequest.type, handleMicrosoftSignin);
  yield takeEvery(signupActions.signupRequest.type, handleSignup);
  yield takeEvery(signupActions.signupPromoRequest.type, handleSignupWithPromo);
  yield takeEvery(forgotActions.forgotRequest.type, handleForgotpassword);
  yield takeEvery(forgotActions.resetRequest.type, handleResetpassword);
  yield takeEvery(authActions.refreshTokenRequest.type, refreshTokenWorker);
  yield takeEvery(authActions.logout.type, handleLoggout);
}

export default authWatcherSaga;
