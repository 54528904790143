import colors from "@assets/colors";
import TypographyStyle from "@assets/typography";
import { StyleSheet } from "react-native";
import { CreateResponsiveStyle, DEVICE_SIZES } from "rn-responsive-styles";

const useStyles = CreateResponsiveStyle(
  {
    linearStyle: {
      position: "absolute",
      zIndex: -1,
      width: "100%",
      height: "100%",
    },
    containerStyle: {
      height: 125
    },
    flexContainer1: {
      height: 150,
      overflow: 'hidden',
      flex: 1,
      flexDirection: 'row',
      position:'relative',
      borderWidth: 0
    },
    i_bg_img2: {
      // flex: 1,'
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      // zIndex: -1,
      height: 200,
      width: 200,
    },
    i_bg_img_two2: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      // right: '17.7%',
      top: 0,
      zIndex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    i_bg_img_three_arrows: {
      width: '100%',
      height: '97%',
      position: 'absolute',
      // right: '17.7%',
      top: 0,
      zIndex: 1,
      justifyContent: 'center',
      alignItems: 'center'
    },
    bg_content2: StyleSheet.flatten([TypographyStyle.heading3Regular,
      {
        flex: 2,
        flexDirection: 'row',
        // backgroundColor: colors.black12,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: '400',
        zIndex: 9,
      }
    ]),
    btn_explore_plans_back: {
      // flex: 0.3,
      // marginRight: '3%',
      alignSelf: 'center',
      width: "50%",
      backgroundColor: 'transparent',
      fontWeight: '700'
    },
    btn_explore_plans: {
      // flex: 0.3,
      // marginRight: '3%',
      alignSelf: 'center',
      width: "50%",
      backgroundColor: '#4168AB',
      fontWeight: '700',
      marginBottom: 10,
    },
    btn_explore_plans_label: {
      fontSize: 12,
      fontWeight: '700',
    },
    textContent: {
      flex: 1.5,
      color: '#FFFFFF',
      fontSize: 12,
      paddingHorizontal: "15%",
      marginBottom: 20,
      textAlign: 'center',
    },
    textContent1: {
      flex: 1.5,
      color: '#FFFFFF',
      fontSize: 23,
      paddingHorizontal: "15%",
      marginBottom: 15,
      textAlign: 'center',
    },
    textContent2: {
      flex: 1.5,
      color: '#FFFFFF',
      fontSize: 23,
      marginLeft: '5%',
    },
    btn_learnmore2: {
      flex: 0.25,
      marginRight: '3%',
      backgroundColor: 'white',
      fontWeight: '700'
    },
    btn_label: {
      fontFamily: "DMSans-Bold",
      color: 'black',
      fontSize: 13,
      lineHeight: 15,
    },
    boldText: {
      fontWeight: "700",
    },
    smallText: {
      fontFamily: "DMSans",
      fontSize: 14,
      fontWeight: '200',
    },
    regularText: {
      fontWeight: '200',
    },
    tmText: {
      fontSize: 13,
      top: -8,
      fontWeight: "normal",
    },
    // BACKGROUND COMPONENT
    



    // old banner css
    flexContainer: {
      flex: 1,
      flexDirection: 'row',
      // backgroundColor: colors.darkBlue,
      height: 150,
      overflow: "hidden"
    },
    i_bg_img: {
      flex: 1,
      width: "100%",
      height: 150,
      zIndex: -1, 
      position: "absolute",
      right: 280
      // alignItems: 'start',
    },
    i_bg_img_two: {
      // flex: 1,
      // width: "100%",
      // height: "100%",
      // resizeMode: "stretch",
      // backgroundColor: colors.black12,
      flex: 1,
      width: 184,
      height: 150,
      zIndex: 2, 
      position: "relative",
      left: 435
    },
    bg_content: {
      backgroundColor: colors.black12,
      width: '52.5%',
      flex: 1,
      flexDirection: 'row',
    },
    btn_learnmore: {
      flex: 0.5,
      margin: 'auto',
      marginRight: 50
    },
    i_bg_mobile:{
      width: "100%",
      height: 167,
      zIndex: -1, 
      position: "absolute",
    },



    imageStyle: {
      width: 26, height: 30, marginTop: 10
    },
    ContentSpacing: {
      flexDirection: "row",
      alignItems: "center",
    },
    btnLabel:{ 
      fontSize: 14
    },
    btnStyles:{
      width:" 40%"
    },
    spacingLeft:{
      width: "50%"
    },
    textAlignmentBanner:{
      flexDirection:"row"
    },
    bannerContainer: {
      flexDirection: "row",
      paddingLeft: 70,
      paddingTop: 34,
      paddingBottom: 34,
      alignItems: "center",
      paddingRight: 70,
      justifyContent: "space-between"
    },
    planButton: {
      flexDirection: "row", 
      paddingTop: 30
    },
    bannerContentText:StyleSheet.flatten([TypographyStyle.heading3Regular,
      { 
        color: colors.white, 
        textAlign: "left",
        fontSize: 25,
      }]),
    priceContent:StyleSheet.flatten([TypographyStyle.heading3Regular,
      { 
        color: colors.white, 
        textAlign:"left",
        fontSize: 18,
        paddingRight: 15,
        alignItems: "center"
      }]),
    buttonSpacing: {
      width: "100%",
      paddingRight: 75
    },  
  },  
  {
    [DEVICE_SIZES.LG]:  {
      i_bg_img: {
        // zIndex: -1, 
        // position: "absolute",
        // right: 500
        // alignItems: 'start',
      },
      bannerContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingRight: 0,
      },

      bannerContentText:{
        fontSize: 18
      },
      planButton: {
        flexDirection: "column", 
        paddingTop: 30,
        alignItems:"flex-end"
      },
      btnStyles: {
        width: "100%"
      },
      buttonSpacing: {
        width: "60%",
      },
      btnLabel:{ 
        fontSize: 15
      },
      spacingLeft:{
        width: "50%"
      },
      priceContent: {
        paddingRight: 20,
        fontSize: 14
      },
      i_bg_img_two2: {
        // right: '17.9%',
      },
      i_bg_img_three_arrows: {
        right: '1%',
      },
      btn_learnmore2: {
        // flex: 0.35,
        marginRight: '5%',
        paddingLeft: 10,
        paddingRight: 10,
        // backgroundColor: '#4168AB',
        fontWeight: '500'
      },
    },
    [DEVICE_SIZES.MD]:  {
      containerStyle: {
        height: 80,
        marginTop: 30
      },
      i_bg_img2: {
        width: '100%',
      },
      i_bg_img_two2: {
        width: '100%',
        height: '100%',
        // right: '17.9%',
      },
      i_bg_img_three_arrows: {
        right: '5%',
      },
      textContent2: {
        fontSize: 15,
        marginLeft: '12%',
      },
      tmText: {
        fontSize: 10,
        top: -5,
      },
      btn_learnmore2: {
        height: 20,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 5,
        marginRight: '5%',
      },
      btn_label: {
        fontSize: 8,
        lineHeight: 8,
      },
    },
    [DEVICE_SIZES.SM]:  {
      containerStyle: {
        height: 80,
        marginTop: 30
      },
      i_bg_img2: {
        width: '100%',
      },
      i_bg_img_two2: {
        width: '100%',
        height: '100%',
        // right: '18%',
      },
      i_bg_img_three_arrows: {
        width: '100%',
        height: '100%',
        // right: '18%',
      },
      textContent2: {
        fontSize: 15,
        marginLeft: '12%',
      },
      tmText: {
        fontSize: 10,
        top: -5,
      },
      btn_learnmore2: {
        height: 20,
        paddingLeft: 0,
        paddingRight: 0,
        borderRadius: 5,
        marginRight: '5%',
      },
      btn_label: {
        fontSize: 8,
        lineHeight: 8,
      },
    },
    [DEVICE_SIZES.XS]:  { 
      containerStyle: {
        height: 80
      },
      i_bg_img2: {
        width: '110%',
      },
      i_bg_img_two2: {
        width: '100%',
        height: '80%',
        // right: '18.28%',
        top: '35.4%',
      },
      i_bg_img_three_arrows: {
        width: '100%',
        height: '80%',
        // right: '18.28%',
        top: '35.4%',
      },
      textContent2: {
        fontSize: 11,
        marginLeft: '12%',
      },
      tmText: {
        fontSize: 8,
        top: -3,
      },
      btn_learnmore2: {
        height: 20,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 5,
        marginRight: '4%',
      },
      btn_label: {
        fontSize: 5,
        lineHeight: 5,
      },
    }
  }
);

export default useStyles;
