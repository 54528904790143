import { useCallback } from "react";
import { useAppDispatch } from "../ducksHook";
import {
  dashboardActions
} from "../slices/dashboard.slice";

type ServiceOperators = {
  setMigratedFiles: (email: string) => void;
  onTakeTour: (email: string) => void;
  onFinishOnboarding: (email: string) => void;
  onDrawerEnable: (params: boolean) => void;
  onActiveDrawerChange: (params: string) => void;
  onDocumentChange: (params: any) => void;
};

export const useDashboardService = (): Readonly<ServiceOperators> => {
  const dispatch = useAppDispatch();

  return {
    setMigratedFiles: useCallback((email) =>  
      dispatch(dashboardActions.setMigratedFiles(email)),
    [dispatch]
    ),
    onTakeTour: useCallback((email) =>  
      dispatch(dashboardActions.setTakeTour(email)),
    [dispatch]
    ),
    onFinishOnboarding: useCallback((email) =>  
      dispatch(dashboardActions.setFinishedOnboarding(email)),
    [dispatch]
    ),
    onDrawerEnable: useCallback((params) =>  
      dispatch(dashboardActions.drawerEnable(params)),
    [dispatch]
    ),
    onActiveDrawerChange: useCallback(
      (params) =>  dispatch(dashboardActions.activeDrawer(params)),
      [dispatch]
    ),
    onDocumentChange: useCallback(
      (params) =>  dispatch(dashboardActions.setDocument(params)),
      [dispatch]
    ),
  };
};

export default useDashboardService;
